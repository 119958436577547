<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-1 pb-1 px-2">
      <div class="document-header">
        <div class="block-divider">
          Стандартні реквізити
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 340px; margin-right: 16px">
            <select_input
                v-model="contractor_id"
                :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                :computed-getter="'get_contractor_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :show-additional-field="true"
                :row_num="0"
                label="Одержувач"
                :show-select-on-focus="false"
                :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                showAdditionalField: true
                              }"
                clearable
            />
          </div>
          <div style="flex: 0 0 320px; margin-right: 16px">
            <select_input
                v-model="contract_id"
                :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                :computed-getter="'get_contractor_contract_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                :owner_id="contractor_id"
                label="Договір"
                :action-props="{owner_id: contractor_id}"
                :show-select-on-focus="false"
                :sync-action-props="true"
                :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: contractor_id},
                                sync_action_props: true
                              }"
            />
          </div>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 340px; margin-right: 16px">
            <select_input
                v-model="cash_flow_item_id"
                :computed-action="'SELECT_CASH_FLOW_ITEM_LIST_ELEMENTS'"
                :computed-getter="'get_cash_flow_item_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Стаття руху грошових коштів: "
                :select-button-object="{
                                name: 'CASH_FLOW_ITEM_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список статей руху грошових коштів',
                                element_title: 'Стаття руху грошових коштів',
                                show: true
                              }"
            />
          </div>
          <div style="flex: 0 0 320px; margin-right: 16px">
            <select_input
                v-model="bank_account_id"
                :computed-action="'SELECT_BANK_ACCOUNT_LIST_ELEMENTS'"
                :computed-getter="'get_bank_account_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                label="Банківський рахунок: "
                :select-button-object="{
                                name: 'BANK_ACCOUNT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список банківських рахунків',
                                element_title: 'Банківський рахунок',
                                show: true
                              }"
                clearable
            />
          </div>
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div style="flex: 0 0 340px; margin-right: 16px">
            <select_input
                v-model="service_id_main"
                :computed-action="'SELECT_SERVICES_WITHOUT_ALL_SERVICE'"
                :computed-getter="'getServicesSelectWithoutAll'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Послуга (основна)"
                :show-select-on-focus="true"
                :sync-action-props="false"
                :select-button-object="{}"
                clearable
            />
          </div>
          <div style="flex: 0 0 320px; margin-right: 16px">
            <select_input
                v-model="service_id_base"
                :computed-action="'SELECT_SERVICES_WITHOUT_ALL_SERVICE'"
                :computed-getter="'getServicesSelectWithoutAll'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Послуга (згорнути)"
                :show-select-on-focus="true"
                :sync-action-props="false"
                :select-button-object="{}"
                clearable
            />
          </div>
        </div>
        <div class="block-divider mt-2">
          Бухгалтерський облік
        </div>
        <div class="document-header-row px-3 pt-2 pb-1">
          <div class="d-flex">
            <div style="flex: 0 0 356px">
              <div style="flex: 0 0 300px; margin-right: 16px">
                <select_input
                    v-model="chart_of_account_id"
                    :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                    :computed-getter="'get_chart_of_accounts_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :show-select-on-focus="false"
                    :show-search-on-focus="true"
                    label="Рахунок бух.обліку Дт"
                    :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              show: true,
                              clearable: true
                            }"
                    :required="true"
                    :clearable="true"
                />
              </div>
            </div>
            <div style="flex: 1 1">
              <div style="width: 320px;">
                <select_input
                    v-model="pay_chart_of_account_id"
                    :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                    :computed-getter="'get_chart_of_accounts_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :show-select-on-focus="false"
                    :show-search-on-focus="true"
                    label="Рахунок бух.обліку Кт"
                    :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              show: true,
                              clearable: true
                            }"
                    :required="true"
                    @selectChange="afterHeaderPayChartAccountChange"
                    :clearable="true"
                />
              </div>
              <div style="width: 320px; margin-top: 12px;" v-if="account_detail_1_type">
                <select_input
                    v-model="account_detail_1_value"
                    :computed-action="subconto_type_select_setting[account_detail_1_type].computed_action"
                    :computed-getter="subconto_type_select_setting[account_detail_1_type].computed_getter"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :show-select-on-focus="false"
                    label="Субконто 1"
                    :required="true"
                    :clearable="true"
                    :select-button-object="{
                                name: subconto_type_select_setting[account_detail_1_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_1_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                />
              </div>
              <div style="width: 320px; margin-top: 12px;" v-if="account_detail_2_type">
                <select_input
                    v-model="account_detail_2_value"
                    :computed-action="subconto_type_select_setting[account_detail_2_type].computed_action"
                    :computed-getter="subconto_type_select_setting[account_detail_2_type].computed_getter"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :show-select-on-focus="false"
                    label="Субконто 2"
                    :required="true"
                    :clearable="true"
                    :owner_id="account_detail_2_type === 'contract' ? account_detail_1_value : null"
                    :action-props="account_detail_2_type === 'contract' ? {owner_id: account_detail_1_value} : null"
                    :sync-action-props="account_detail_2_type === 'contract'"
                    :select-button-object="{
                                name: subconto_type_select_setting[account_detail_2_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_2_type].select_button_object.element_title,
                                show: true,
                                clearable: true,
                                action_props: account_detail_2_type === 'contract' ? {owner_id: account_detail_1_value} : null,
                                sync_action_props: account_detail_2_type === 'contract'
                              }"
                />
              </div>
              <div style="width: 320px; margin-top: 12px;" v-if="account_detail_3_type">
                <select_input
                    v-model="account_detail_3_value"
                    :computed-action="subconto_type_select_setting[account_detail_3_type].computed_action"
                    :computed-getter="subconto_type_select_setting[account_detail_3_type].computed_getter"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :show-select-on-focus="false"
                    label="Субконто 3"
                    :required="true"
                    :clearable="true"
                    :select-button-object="{
                                name: subconto_type_select_setting[account_detail_3_type].select_button_object.name,
                                group: subconto_type_select_setting[account_detail_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[account_detail_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[account_detail_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[account_detail_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[account_detail_3_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS,
  UPDATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS,
  REMOVE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {QUESTION_SHOW} from "@/store/actions/question";
import {subconto_type_select_setting} from "@/utils/accounting";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'accrual_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_House_Accounting_Payments",
  mixins: [ModalComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
  },
  data() {
    return {
      subconto_type_select_setting,
      charts: [],
      document_state: {
        ready: false,
        header_ready: false,
        show: false,
        errors: []
      },
      contractor_id: this.item.contractor_id,
      contract_id: this.item.contract_id,
      cash_flow_item_id: this.item.cash_flow_item_id,
      bank_account_id: this.item.bank_account_id,
      pay_chart_of_account_id: this.item.pay_chart_of_account_id,
      account_detail_1_type: this.item.account_detail_1_type,
      account_detail_2_type: this.item.account_detail_2_type,
      account_detail_3_type: this.item.account_detail_3_type,
      account_detail_1_value: this.item.account_detail_1_value,
      account_detail_2_value: this.item.account_detail_2_value,
      account_detail_3_value: this.item.account_detail_3_value,
      chart_of_account_id: this.item.chart_of_account_id,
      service_id_main: this.item.service_id_main,
      service_id_base: this.item.service_id_base,
    }
  },
  methods: {
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },
    afterHeaderPayChartAccountChange(payload) {
      let details = {}
      if ('text' in payload && 'value' in payload) {
        details = this.get_account_details(payload.value)
      } else {
        details = this.get_account_details(payload.id)
      }
      this.account_detail_1_type = details.account_detail_1.type
      this.account_detail_1_value = null
      this.account_detail_2_type = details.account_detail_2.type
      this.account_detail_2_value = null
      this.account_detail_3_type = details.account_detail_3.type
      this.account_detail_3_value = null
    },

    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'contractor_id': {id: 'contractor_id', text: 'Не заповнений платник'},
        'contract_id': {id: 'contract_id', text: 'Не заповнений договір платника'},
        'cash_flow_item_id': {id: 'cash_flow_item_id', text: 'Не заповнена стаття руху грошових коштів'},
        'bank_account_id': {id: 'bank_account_id', text: 'Не заповнений банківський рахунок'},
        'chart_of_account_id': {id: 'chart_of_account_id', text: 'Не заповнений рахунок бух. обліку Дт'},
        'service_id_main': {id: 'service_id_main', text: 'Не заповнена послуга (основна)'},
        'service_id_base': {id: 'service_id_base', text: 'Не заповнена послуга (згорнути)'},
        'pay_chart_of_account_id': {id: 'pay_chart_of_account_id', text: 'Не заповнений рахунок бух. обліку Кт'},
      }

      if (this.account_detail_1_type) {
        header['account_detail_1_value'] = {
          id: 'account_detail_1_value',
          text: `Не заповнено субконто №1: ${this.subconto_type_select_setting[this.account_detail_1_type].select_button_object.element_title}`
        }
      }

      if (this.account_detail_2_type) {
        header['account_detail_2_value'] = {
          id: 'account_detail_2_value',
          text: `Не заповнено субконто №2: ${this.subconto_type_select_setting[this.account_detail_2_type].select_button_object.element_title}`
        }
      }

      if (this.account_detail_3_type) {
        header['account_detail_3_value'] = {
          id: 'account_detail_3_value',
          text: `Не заповнено субконто №3: ${this.subconto_type_select_setting[this.account_detail_3_type].select_button_object.element_title}`
        }
      }

      const document_table = {
        // 'tax_id': {id: 'tax_id', table: 'Таблиця', text: 'Не заповнена ставка ПДВ'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(document_table, 'Таблиця', 'table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }
    },

    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.contractor_id = this.item.contractor_id
      this.contract_id = this.item.contract_id
      this.cash_flow_item_id = this.item.cash_flow_item_id
      this.bank_account_id = this.item.bank_account_id
      this.pay_chart_of_account_id = this.item.pay_chart_of_account_id
      this.account_detail_1_type = this.item.account_detail_1_type
      this.account_detail_2_type = this.item.account_detail_2_type
      this.account_detail_3_type = this.item.account_detail_3_type
      this.account_detail_1_value = this.item.account_detail_1_value
      this.account_detail_2_value = this.item.account_detail_2_value
      this.account_detail_3_value = this.item.account_detail_3_value
      this.chart_of_account_id = this.item.chart_of_account_id
      this.service_id_main = this.item.service_id_main
      this.service_id_base = this.item.service_id_base
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення налаштування для створення платіжок поквартирного обліку`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        pay_chart_of_account_id: this.pay_chart_of_account_id,
        chart_of_account_id: this.chart_of_account_id,
        cash_flow_item_id: this.cash_flow_item_id,
        contractor_id: this.contractor_id,
        contract_id: this.contract_id,
        bank_account_id: this.bank_account_id,
        service_id_main: this.service_id_main,
        service_id_base: this.service_id_base,
        account_detail_1_type: this.account_detail_1_type,
        account_detail_2_type: this.account_detail_2_type,
        account_detail_3_type: this.account_detail_3_type,
        account_detail_1_value: this.account_detail_1_value,
        account_detail_2_value: this.account_detail_2_value,
        account_detail_3_value: this.account_detail_3_value,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.contractor_id = payload.contractor_id
              this.contract_id = payload.contract_id
              this.cash_flow_item_id = payload.cash_flow_item_id
              this.bank_account_id = payload.bank_account_id
              this.pay_chart_of_account_id = payload.pay_chart_of_account_id
              this.account_detail_1_type = payload.account_detail_1_type
              this.account_detail_2_type = payload.account_detail_2_type
              this.account_detail_3_type = payload.account_detail_3_type
              this.account_detail_1_value = payload.account_detail_1_value
              this.account_detail_2_value = payload.account_detail_2_value
              this.account_detail_3_value = payload.account_detail_3_value
              this.chart_of_account_id = payload.chart_of_account_id
              this.service_id_main = payload.service_id_main
              this.service_id_base = payload.service_id_base
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_HOUSE_ACCOUNTING_PAYMENTS_SETTINGS_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    chartOfAccountAPI.get_all()
        .then(response => response.data)
        .then(data => {
          this.charts = data
        })
  }
}
</script>
